import { render, staticRenderFns } from "./NorthLegend.vue?vue&type=template&id=542cd9ec&scoped=true&"
import script from "./NorthLegend.vue?vue&type=script&lang=js&"
export * from "./NorthLegend.vue?vue&type=script&lang=js&"
import style0 from "./NorthLegend.vue?vue&type=style&index=0&id=542cd9ec&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "542cd9ec",
  null
  
)

export default component.exports