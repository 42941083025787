import { render, staticRenderFns } from "./SearchBarNew.vue?vue&type=template&id=bbe842e4&scoped=true&"
import script from "./SearchBarNew.vue?vue&type=script&lang=js&"
export * from "./SearchBarNew.vue?vue&type=script&lang=js&"
import style0 from "./SearchBarNew.vue?vue&type=style&index=0&id=bbe842e4&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbe842e4",
  null
  
)

export default component.exports