<template>
  <div class="map-container">
    <div class="map-div" id="map">
      <div id="map-zoom-control" tabindex="-1"></div>
      <div id="map-attr-control" tabindex="-1"></div>
    </div>

    <search-bar-new class="search-container" @popup="searchPopupHandler"></search-bar-new>

    <SolarResPayback
      class="map-popups"
      ref="solarResult"
      :content="solarResultPopupContent"
      :solarConfig="solarConfig"
      :pvAverage="pvAverage"
      @popupWithVnodeClose="solarResultCardClose"
    ></SolarResPayback>

    <ol-popup
      class="map-popups"
      ref="searchPopup"
      :content="searchPopupContent"
      @popupClose="searchPopupClose()"
    ></ol-popup>

    <ol-popup
      class="map-popups"
      ref="buildingPopup"
      :content="buildingPopupContent"
      :type="buildingPopupType"
      @popupClose="buildingPopupClose()"
    ></ol-popup>

    <NorthLegend class="north-legend" @on-rotate-north="onRotateNorth" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Overlay from "ol/Overlay";
import { Stroke, Style, Fill } from "ol/style";
import { convertToTurf, writeTurfGeometry, getArea, proj2326Obj } from "./utils";
// import { addDraw, } from "./utils";
import { fromLonLat } from "ol/proj";
import "ol/ol.css";
import { Map, View } from "ol";
import { defaults as DefControl } from "ol/control";
import { ScaleLine } from "ol/control";

// import Draw from "ol/interaction/Draw";
import Select from "ol/interaction/Select";
import { getCenter } from "ol/extent";
import { Vector as VectorSource } from "ol/source";
import { Vector as VectorLayer } from "ol/layer";

import * as turf from "@turf/turf";

import DrawWithLabel from "./label-interaction";
import layersInstance from "./layers";
import OlPopup from "./OlPopup";
import SolarResPayback from "./SolarResPayback";
import NorthLegend from "./NorthLegend";
import SearchBarNew from "./SearchBarNew";
import { ServiceHost } from "../../../config/index";

export default {
  name: "MyMap",
  components: {
    OlPopup,
    SearchBarNew,
    NorthLegend,
    SolarResPayback
  },
  computed: {
    ...mapGetters({
      language: "localize/language",
      localizedContent: "localize/content",
      // remoteHost: "config/remoteHost",
      remoteHost: "config/remoteHost",
      sValue: "solarLayer/tiltingAngleValue",
      aValue: "solarLayer/orientationValue",
      panelEfficencyValue: "solarLayer/panelEfficencyValue",
      clearState: "drawTool/clearState",
      transparencyValue: "solarLayer/transparencyValue",
      currentMap: "basemap/currentMap"
    }),
    drawToolActivate: {
      get() {
        return this.$store.getters["drawTool/drawToolActivate"];
      },
      set(val) {
        this.$store.commit("drawTool/set_draw_tool_activate", val);
      }
    },
    transparencyValue: {
      get() {
        return this.$store.getters["solarLayer/transparencyValue"];
      },
      set(val) {
        this.$store.commit("solarLayer/set_transparency_value", val);
      }
    },
    buildingSource: {
      get() {
        return layersInstance.layers[4].getSource();
      }
    }
  },
  methods: {
    initMap() {
      let searchPopupOverlay = new Overlay({
        element: this.$refs["searchPopup"].$el,
        autoPan: true,
        offset: [-225, 0]
      });

      let buildingPopupOverlay = new Overlay({
        element: this.$refs["buildingPopup"].$el,
        offset: [-225, 0],
        autoPan: true
      });

      let solarResultOverlay = new Overlay({
        element: this.$refs["solarResult"].$el,
        offset: [0, 0],
        autoPan: true,
        positioning: "center-left"
      });

      let drawVectorSource = new VectorSource();
      let drawVectorLayer = new VectorLayer({
        source: drawVectorSource
      });

      const map = new Map({
        target: "map",
        layers: [...layersInstance.layers, drawVectorLayer],
        overlays: [searchPopupOverlay, buildingPopupOverlay, solarResultOverlay],
        view: new View({
          extent: proj2326Obj.extent,
          projection: proj2326Obj.projection,
          center: fromLonLat([114.1604, 22.301239], proj2326Obj.projection), //114.20847, 22.29227
          zoom: 11,
          maxZoom: 22
        }),
        controls: DefControl({
          zoom: true,
          attributionOptions: {
            target: document.getElementById("map-attr-control")
          },
          zoomOptions: {
            className: "ol-zoom-custom",
            target: document.getElementById("map-zoom-control")
          },
          rotate: false
        })
      });

      map.addControl(new ScaleLine({ units: "metric" }));

      let drawInteraction = new DrawWithLabel({
        source: drawVectorSource,
        type: "Polygon",
        map: map,
        finishCondition: event => {
          const polygonFirstCoordinatePx = map.getPixelFromCoordinate(this.polygonFirstCoordinate);

          if (
            (event.pixel[0] - 10 >= polygonFirstCoordinatePx[0] ||
              event.pixel[0] + 10 <= polygonFirstCoordinatePx[0]) &&
            (event.pixel[0] - 10 >= polygonFirstCoordinatePx[1] ||
              event.pixel[0] + 10 <= polygonFirstCoordinatePx[1])
          ) {
            return false;
          }
          return true;
        }
      });

      drawInteraction.on("drawstart", event => {
        this.polygonFirstCoordinate = event.feature.getGeometry().getFirstCoordinate();
      });

      //只能有一个feature
      drawVectorSource.on("addfeature", () => {
        if (drawVectorSource.getFeatures().length === 2) {
          drawVectorSource.removeFeature(drawVectorSource.getFeatures()[0]);
        }
      });

      map.addInteraction(drawInteraction);
      this.drawVectorSource = drawVectorSource;
      this.drawInteraction = drawInteraction;
      this.drawInteraction.setActive(false);
      this.searchPopupOverlay = searchPopupOverlay;
      this.buildingPopupOverlay = buildingPopupOverlay;
      this.solarResultOverlay = solarResultOverlay;
      this.map = map;

      // set attribute tabindex after map initialized
      map.once("postrender", function() {
        const elem = document.querySelector("#map-attr-control .attr-text");
        elem.tabIndex = -1;
      });

      const screenWidth =
        window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

      if (screenWidth <= 800) {
        document.getElementById("map").focus();
      }
    },
    onRotateNorth() {
      this.map.getView().setRotation(0);
    },
    searchPopupHandler({ position, popupContent }) {
      let that = this;
      that.searchPopupOverlay.setPosition(fromLonLat(position, proj2326Obj.projection));
      that.searchPopupContent = popupContent;
      that.map.getView().setZoom(19);
      that.map.getView().setCenter(fromLonLat(position, proj2326Obj.projection));
    },
    solarResultPopupHandler({ position }) {
      let that = this;
      that.solarResultOverlay.setPosition(position);
    },
    buildingPopupHandler({ position, popupContent, type }) {
      let that = this;
      that.buildingPopupOverlay.setPosition(position);
      that.buildingPopupContent = popupContent;
      that.buildingPopupType = type;
    },
    searchPopupClose() {
      this.searchPopupOverlay.setPosition(undefined);
    },
    buildingPopupClose() {
      this.buildingPopupOverlay.setPosition(undefined);
      this.buildingPopupContent = [];
      this.buildingPopupType = null;
      if (this.selectInteraction) {
        this.selectInteraction.getFeatures().clear();
      }
      if (this.selectOpenSpaceInteraction) {
        this.selectOpenSpaceInteraction.getFeatures().clear();
      }
    },
    solarResultCardClose() {
      this.drawedFeature = undefined;
      this.solarResultCardVisible = false;
      this.paybackCardVisible = false;
      this.drawVectorSource.clear(true);
      this.drawInteraction.giveUpDrawing();
      this.solarResultOverlay.setPosition(undefined);
    },
    // type: "building" or "openspace"
    requestSolarComputeResult(drawedFeature, type, selectedFeature) {
      var that = this;
      that.axios
        .post(that.remoteHost + "/solarCompute/solarInfo", {
          s: that.$store.getters["solarLayer/tiltingAngleValue"],
          a: that.$store.getters["solarLayer/orientationValue"],
          geometry: writeTurfGeometry(convertToTurf(drawedFeature)),
          efficiency: that.$store.getters["solarLayer/panelEfficencyValue"] / 100,
          areaDrawed: parseFloat(getArea(drawedFeature).toFixed(1)),
          type
        })
        .then(({ data }) => {
          that.$set(
            that.solarResultPopupContent,
            "areaSelected",
            parseFloat(getArea(drawedFeature).toFixed(1))
          );
          that.$set(
            that.solarResultPopupContent,
            "areaSuitableForPVInstallation",
            parseFloat(data.message.areaSuitable)
          );
          that.$set(
            that.solarResultPopupContent,
            "installationCapacity",
            parseFloat(data.message.installationCapacity)
          );
          that.$set(
            that.solarResultPopupContent,
            "electricityGeneration",
            parseFloat(data.message.annualElectricityGeneration)
          );
          that.$set(that.solarResultPopupContent, "FitIncome", parseFloat(data.message.income));
          // 如果畫了一個polygon, 顯示Average Solar Irradiation:
          that.$set(
            that.solarResultPopupContent,
            "averageSolarIrradiation",
            parseFloat(data.message.avg_solar)
          );
          that.$set(
            that.solarResultPopupContent,
            "averageSolarIrradiation",
            parseFloat(data.message.avg_solar)
          );

          if (type === "openspace") {
            that.$set(that.solarResultPopupContent, "typeOptions", {
              ENG: selectedFeature.get("Type"),
              "CN-ZH": selectedFeature.get("Type_SC"),
              "CN-TW": selectedFeature.get("Type_TC")
            });
          } else if (type === "building") {
            that.$set(that.solarResultPopupContent, "typeOptions", null);
          }

          //包含值为0时提示用户
          if (data.message.containZero) {
            that.$msgbox({
              customClass: "messagebox-message",
              closeOnClickModal: false,

              message: that.localizedContent.message.containZero,
              type: "warning",
              confirmButtonText:
                that.localizedContent.message.alertMessage.confirmButtonTextWithoutChinese
            });
          }
          // 在请求的时候计算,如果installationCapacity大于1000就弹框
          if (parseFloat(data.message.installationCapacity) > 1000) {
            that.$msgbox({
              customClass: "messagebox-message",
              closeOnClickModal: false,

              message: that.localizedContent.message.fitIncome,
              type: "warning",
              confirmButtonText:
                that.localizedContent.message.alertMessage.confirmButtonTextWithoutChinese
            });
          }
          let boundingbox = turf.bbox(convertToTurf(drawedFeature));
          that.solarResultPopupHandler({
            position: [boundingbox[2], (boundingbox[1] + boundingbox[3]) / 2]
          });
        })
        .catch(err => {
          console.error(err);

          that.$msgbox({
            customClass: "messagebox-message",
            closeOnClickModal: false,

            message: that.localizedContent.message.error,
            type: "error",
            confirmButtonText:
              that.localizedContent.message.alertMessage.confirmButtonTextWithoutChinese
          });
          that.drawVectorSource.clear();
        });
    },
    computeTable(areaSuit, pvAverage, efficiency) {
      // Get the performance ratio from table
      let performanceRatio = this.solarConfig?.performanceRatio || 0;

      // Get the utilization rate from table
      let utilizationRateValue = this.solarConfig?.s0a0 || 0;

      // get the shading factor
      let shading = this.solarConfig?.sfs0a0 || 0;

      // Installation Capacity= Area suitable for installation*Utilization rate*Efficiency
      let installationCapacity = areaSuit * utilizationRateValue * efficiency;

      // Annual Electricity Generation=Installation Capacity*(PV_average (in W))/1000*Performance ratio
      let annualElectricityGeneration =
        installationCapacity * pvAverage * performanceRatio * shading;
      // FiT income
      let income = 0;

      if (installationCapacity <= 10) {
        const incomeHigh = this.solarConfig?.incomeHigh;
        income = incomeHigh * annualElectricityGeneration;
      } else if (installationCapacity > 10 && installationCapacity <= 200) {
        const incomeMid = this.solarConfig?.incomeMid;
        income = incomeMid * annualElectricityGeneration;
      } else if (installationCapacity > 200 && installationCapacity <= 1000) {
        const incomeLow = this.solarConfig?.incomeLow;
        income = incomeLow * annualElectricityGeneration;
      }

      return {
        installationCapacity,
        annualElectricityGeneration,
        incomeNew: income
      };
    },
    selectHandler() {
      let that = this;

      this.selectInteraction = new Select({
        // layers: [layersInstance.layers[4]],
        layers: [layersInstance.building_layer],
        hitTolerance: 2,
        multi: false
      });

      this.map.addInteraction(this.selectInteraction);

      this.selectInteraction.on("select", eventArgs => {
        if (eventArgs.deselected.length !== 0) {
          //如果取消选中了一个要素
          that.buildingPopupClose();
          if (eventArgs.selected.length === 0) {
            return;
          }
        }

        const feature = eventArgs.selected[0];

        // Based on the requirement of EMSD dated 29 Dec 2020 to adjust the value of solar irradiation
        // to the same value as HKO King's Park Station
        // hko_ratio = 1390835.432 /1400152.500 (average of HKO values 2009-2018)/(simulated value from model)
        const hko_ratio = 0.9933456762745487;
        const avg_solar = feature.values_.avg_solar * hko_ratio;
        this.pvAverage = avg_solar;

        // const electricit = feature.values_.electricit * hko_ratio;
        // const income = feature.values_.income * hko_ratio;
        const { installationCapacity, annualElectricityGeneration, incomeNew } = this.computeTable(
          feature.values_.area_suit,
          avg_solar,
          this.$store.getters["solarLayer/panelEfficencyValue"] / 100
        );

        // 判断村庄这一类的建筑物
        if (parseFloat(feature.values_.village.toFixed(0)) === 1) {
          that.$msgbox({
            customClass: "messagebox-message",
            closeOnClickModal: false,

            message: that.localizedContent.message.village,
            type: "warning",
            confirmButtonText:
              that.localizedContent.message.alertMessage.confirmButtonTextWithoutChinese
          });
        }

        let popupContent = [
          {
            label: that.localizedContent.buildingPopup.totalRoofArea,
            value:
              parseFloat(feature.values_.area.toFixed(1)).toLocaleString("en-US") +
              " " +
              that.localizedContent.units["m2"]
          },
          {
            label: that.localizedContent.buildingPopup.areaSuitableForInstallation,
            value:
              parseFloat(feature.values_.area_suit.toFixed(1)).toLocaleString("en-US") +
              " " +
              that.localizedContent.units["m2"]
          },
          {
            label: that.localizedContent.buildingPopup.averageSolar,
            value:
              parseFloat(avg_solar.toFixed(1)).toLocaleString("en-US") +
              " " +
              that.localizedContent.units["kWh/m2"]
          },
          {
            label: that.localizedContent.buildingPopup.installationCapacity,
            value:
              // parseFloat(feature.values_.install_ca.toFixed(1)).toLocaleString("en-US") +
              parseFloat(installationCapacity.toFixed(1)).toLocaleString("en-US") +
              " " +
              that.localizedContent.units["kW"]
          },
          {
            label: that.localizedContent.buildingPopup.expectedAnnualElectricityGeneration,
            value:
              // parseFloat(electricit.toFixed(1)).toLocaleString("en-US") +
              parseFloat(annualElectricityGeneration.toFixed(1)).toLocaleString("en-US") +
              " " +
              that.localizedContent.units["kWh"]
          },
          {
            label: that.localizedContent.buildingPopup.expectedAnnualFiTIncome,
            value:
              // parseFloat(income.toFixed(1)).toLocaleString("en-US") +
              parseFloat(incomeNew.toFixed(1)).toLocaleString("en-US") +
              " " +
              that.localizedContent.units["hk$"]
          }
        ];

        // 在这里判断一下，如果建筑物的太阳能产值大于1000，在建筑物弹框里面不显示income
        // if (parseFloat(feature.values_.install_ca.toFixed(0)) > 1000) {
        if (installationCapacity > 1000) {
          popupContent.pop();
          that.$msgbox({
            customClass: "messagebox-message",
            closeOnClickModal: false,

            message: that.localizedContent.message.fitIncome,
            type: "warning",
            confirmButtonText:
              that.localizedContent.message.alertMessage.confirmButtonTextWithoutChinese
          });
        }

        that.buildingPopupHandler({
          // position: turf.centroid(convertToTurf(feature)).geometry.coordinates,
          position: eventArgs.mapBrowserEvent.coordinate,
          popupContent: popupContent,
          type: "building"
        });
      });

      this.drawInteraction.on("change:active", eventArgs => {
        setTimeout(() => {
          this.selectInteraction.setActive(eventArgs.oldValue);
          this.selectOpenSpaceInteraction.setActive(eventArgs.oldValue);
        }, 300);
      });
    },
    selectHandlerOpenSpace() {
      let that = this;

      this.selectOpenSpaceInteraction = new Select({
        // layers: [layersInstance.layers[4]],
        layers: [layersInstance.open_space_shp_layer],
        hitTolerance: 2,
        multi: false
      });

      this.map.addInteraction(this.selectOpenSpaceInteraction);

      this.selectOpenSpaceInteraction.on("select", eventArgs => {
        if (eventArgs.deselected.length !== 0) {
          //如果取消选中了一个要素
          that.buildingPopupClose();
          if (eventArgs.selected.length === 0) {
            return;
          }
        }

        const feature = eventArgs.selected[0];

        // Based on the requirement of EMSD dated 29 Dec 2020 to adjust the value of solar irradiation
        // to the same value as HKO King's Park Station
        // hko_ratio = 1390835.432 /1400152.500 (average of HKO values 2009-2018)/(simulated value from model)
        const hko_ratio = 0.9933456762745487;
        const avg_solar = feature.values_.avg_solar * hko_ratio;
        this.pvAverage = avg_solar;

        // const electricit = feature.values_.electricit * hko_ratio;
        // const income = feature.values_.income * hko_ratio;
        const { installationCapacity, annualElectricityGeneration, incomeNew } = this.computeTable(
          feature.values_.area_suit,
          avg_solar,
          this.$store.getters["solarLayer/panelEfficencyValue"] / 100
        );

        let popupContent = [
          {
            label: that.localizedContent.buildingPopup.openSpaceType,
            value:
              this.language === "CN_ZH"
                ? feature.values_.Type_SC
                : this.language === "CN_TW"
                ? feature.values_.Type_TC
                : feature.values_.Type,
            options: {
              ENG: feature.values_.Type,
              "CN-ZH": feature.values_.Type_SC,
              "CN-TW": feature.values_.Type_TC
            }
          },
          {
            label: that.localizedContent.buildingPopup.openSpaceArea,
            value:
              parseFloat(feature.values_.area.toFixed(1)).toLocaleString("en-US") +
              " " +
              that.localizedContent.units["m2"]
          },
          {
            label: that.localizedContent.buildingPopup.areaSuitableForInstallation,
            value:
              parseFloat(feature.values_.area_suit.toFixed(1)).toLocaleString("en-US") +
              " " +
              that.localizedContent.units["m2"]
          },
          {
            label: that.localizedContent.buildingPopup.averageSolar,
            value:
              parseFloat(avg_solar.toFixed(1)).toLocaleString("en-US") +
              " " +
              that.localizedContent.units["kWh/m2"]
          },
          {
            label: that.localizedContent.buildingPopup.installationCapacity,
            value:
              // parseFloat(feature.values_.install_ca.toFixed(1)).toLocaleString("en-US") +
              parseFloat(installationCapacity.toFixed(1)).toLocaleString("en-US") +
              " " +
              that.localizedContent.units["kW"]
          },
          {
            label: that.localizedContent.buildingPopup.expectedAnnualElectricityGeneration,
            value:
              // parseFloat(electricit.toFixed(1)).toLocaleString("en-US") +
              parseFloat(annualElectricityGeneration.toFixed(1)).toLocaleString("en-US") +
              " " +
              that.localizedContent.units["kWh"]
          },
          {
            label: that.localizedContent.buildingPopup.expectedAnnualFiTIncome,
            value:
              // parseFloat(income.toFixed(1)).toLocaleString("en-US") +
              parseFloat(incomeNew.toFixed(1)).toLocaleString("en-US") +
              " " +
              that.localizedContent.units["hk$"]
          }
        ];

        // 在这里判断一下，如果建筑物的太阳能产值大于1000，在建筑物弹框里面不显示income
        // if (parseFloat(feature.values_.install_ca.toFixed(0)) > 1000) {
        if (installationCapacity > 1000) {
          popupContent.pop();
          that.$msgbox({
            customClass: "messagebox-message",
            closeOnClickModal: false,

            message: that.localizedContent.message.fitIncome,
            type: "warning",
            confirmButtonText:
              that.localizedContent.message.alertMessage.confirmButtonTextWithoutChinese
          });
        }

        that.buildingPopupHandler({
          // position: turf.centroid(convertToTurf(feature)).geometry.coordinates,
          position: eventArgs.mapBrowserEvent.coordinate,
          type: "openspace",
          popupContent: popupContent
        });
      });

      this.drawInteraction.on("change:active", eventArgs => {
        setTimeout(() => {
          this.selectInteraction.setActive(eventArgs.oldValue);
          this.selectOpenSpaceInteraction.setActive(eventArgs.oldValue);
        }, 300);
      });
    },
    debounce(func, wait) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        func();
      }, wait);
    },
    changeBuildingLayerWidth() {
      let that = this;
      this.map.getView().on("change:resolution", () => {
        that.debounce(() => {
          let layerGroup = that.map.getLayerGroup().getLayers();
          layerGroup.forEach(layer => {
            if (layer.getProperties().id === "building Layer") {
              layer.setStyle(
                new Style({
                  stroke: new Stroke({
                    color: "rgba(75,163,254, 1.0)",
                    width: 1 / that.map.getView().getResolution()
                  }),
                  fill: new Fill({
                    color: "rgba(0, 0, 0, 0)"
                  })
                })
              );
            }
          });
        }, 300);
      });
    }
  },
  watch: {
    clearState() {
      this.drawInteraction.giveUpDrawing();
      this.drawInteraction.setActive(false);
      this.solarResultCardClose();
    },
    drawToolActivate(val) {
      var that = this;

      //判断进入绘制过程
      if (val === true) {
        //当前在绘制过程中，那么移除绘制工具，重新添加
        if (this.drawInteraction.getProperties().active === true) {
          this.drawInteraction.giveUpDrawing();
        }

        this.drawInteraction.setActive(true);
        this.selectInteraction.setActive(false);
        this.selectOpenSpaceInteraction.setActive(false);
        that.buildingPopupClose();

        this.drawInteraction.once("drawend", eventArgs => {
          that.drawedFeature = undefined;

          //逻辑是首先拿到绘制的要素，然后获取要素中的一个坐标。
          //针对这个坐标，获取它在建筑物图层上最近的一个要素
          //然后判断这两个要素是不是包含的关系
          let drawedFeature = eventArgs.feature;
          that.map.getView().setCenter(getCenter(drawedFeature.getGeometry().getExtent()));
          let coordinateInDrawedFeature = drawedFeature.getGeometry().getFirstCoordinate();

          const buildingFeatures = that.$store.getters["drawTool/buildingLayer"]
            .getSource()
            .getFeaturesAtCoordinate(coordinateInDrawedFeature);

          const openSpaceFeatures = that.$store.getters["drawTool/openSpaceShpLayer"]
            .getSource()
            .getFeaturesAtCoordinate(coordinateInDrawedFeature);

          console.log({
            buildingFeatures,
            openSpaceFeatures
          });

          if (buildingFeatures.length === 0 && openSpaceFeatures.length === 0) {
            that.drawVectorSource.clear();
            that.solarResultCardVisible = false;
            that.$msgbox({
              customClass: "messagebox-message",
              closeOnClickModal: false,
              message: that.localizedContent.message.drawError,
              type: "error",
              confirmButtonText:
                that.localizedContent.message.alertMessage.confirmButtonTextWithoutChinese
            });
            that.drawInteraction.setActive(false);
            this.selectInteraction.setActive(true);
            this.selectOpenSpaceInteraction.setActive(true);
            return;
          }

          // Handle building first
          // Skip openspace whenevet there is building selected
          if (buildingFeatures.length > 0) {
            const drawedTurfFeature = convertToTurf(drawedFeature);
            const buildingTurfFeature = convertToTurf(buildingFeatures[0]);
            const isInSideBuilding = turf.booleanWithin(drawedTurfFeature, buildingTurfFeature);
            if (!isInSideBuilding) {
              that.drawVectorSource.clear();
              that.solarResultCardVisible = false;
              that.$msgbox({
                closeOnClickModal: false,
                customClass: "messagebox-message",
                message: that.localizedContent.message.drawError,
                type: "error",
                confirmButtonText:
                  that.localizedContent.message.alertMessage.confirmButtonTextWithoutChinese
              });
            } else {
              that.requestSolarComputeResult(drawedFeature, "building");
              // 判断村庄这一类的建筑物
              if (parseFloat(buildingFeatures[0].values_.village.toFixed(0)) === 1) {
                that.$msgbox({
                  customClass: "messagebox-message",
                  message: that.localizedContent.message.village,
                  type: "warning",
                  confirmButtonText:
                    that.localizedContent.message.alertMessage.confirmButtonTextWithoutChinese
                });
              }
              that.drawedFeature = drawedFeature;
            }

            this.drawInteraction.setActive(false);
          } else {
            if (openSpaceFeatures.length > 0) {
              // Alert when PV Panel Tilting Angle is not 0
              if (this.sValue !== 0) {
                that.$msgbox({
                  customClass: "messagebox-message",
                  message: that.localizedContent.noOpenSpace,
                  type: "warning",
                  confirmButtonText:
                    that.localizedContent.message.alertMessage.confirmButtonTextWithoutChinese
                });
                this.drawInteraction.setActive(false);
                this.selectInteraction.setActive(true);
                this.selectOpenSpaceInteraction.setActive(true);
                return;
              }

              let isInsideOpenspace = false;
              let selectedOpenspace = null;
              const drawedTurfFeature = convertToTurf(drawedFeature);
              for (let i = 0; i < openSpaceFeatures.length; i++) {
                const openSpaceTurfFeature = convertToTurf(openSpaceFeatures[i]);
                const isInside = turf.booleanWithin(drawedTurfFeature, openSpaceTurfFeature);
                if (isInside) {
                  isInsideOpenspace = isInside;
                  selectedOpenspace = openSpaceFeatures[i];
                  break;
                }
              }

              if (isInsideOpenspace) {
                that.requestSolarComputeResult(drawedFeature, "openspace", selectedOpenspace);
              } else {
                that.drawVectorSource.clear();
                that.solarResultCardVisible = false;
                that.$msgbox({
                  closeOnClickModal: false,
                  customClass: "messagebox-message",
                  message: that.localizedContent.message.drawError,
                  type: "error",
                  confirmButtonText:
                    that.localizedContent.message.alertMessage.confirmButtonTextWithoutChinese
                });
              }
              this.drawInteraction.setActive(false);
            }
          }
        });
        this.selectInteraction.setActive(true);
        this.selectOpenSpaceInteraction.setActive(true);
      }
    },
    sValue() {
      if (this.drawedFeature) {
        this.requestSolarComputeResult(this.drawedFeature);
      }
    },
    aValue() {
      if (this.drawedFeature) {
        this.requestSolarComputeResult(this.drawedFeature);
      }
    },
    panelEfficencyValue() {
      if (this.drawedFeature) {
        this.requestSolarComputeResult(this.drawedFeature);
      }
    },
    transparencyValue() {
      const newTransparency = 100 - this.transparencyValue;
      // layersInstance.layers[3].values_.opacity = newTransparency / 100;
      layersInstance.solar_layer.values_.opacity = newTransparency / 100;
      layersInstance.open_space_solar_layer.values_.opacity = newTransparency / 100;
      this.map.render();
    }
  },
  data() {
    return {
      drawedFeature: null,
      solarResultCardVisible: false,
      paybackCardVisible: false,
      searchPopupContent: [],
      buildingPopupContent: [],
      buildingPopupType: null,
      solarResultPopupContent: {},
      map: null,
      searchPopupOverlay: null,
      buildingPopupOverlay: null,
      solarResultOverlay: null,
      drawInteraction: null,
      polygonFirstCoordinate: null,
      selectInteraction: null,
      selectOpenSpaceInteraction: null,
      drawVectorSource: null,
      solarConfig: {},
      pvAverage: 1
    };
  },
  async mounted() {
    this.initMap();
    this.selectHandler();
    this.selectHandlerOpenSpace();
    this.changeBuildingLayerWidth();
    this.axios.get(ServiceHost + "/sqlite/getAll").then(resp => {
      this.solarConfig = resp?.data?.data;
    });
  },

  async beforeDestroy() {
    this.map.setTarget(null);
    this.map = null;
  }
};
</script>

<style scoped>
.map-container {
  position: relative;
  height: 100%;
}

#map {
  width: 100%;
  height: 100%;
}

.map-popups {
  text-align: left;
}

.search-container {
  z-index: 2000;
  right: 15px;
  top: 15px;
  position: absolute;
  width: 400px;
}

.north-legend {
  z-index: 2000;
  position: absolute;
  left: 12px;
  bottom: 32px;
  color: #ffffff;
}

.customMessage > p {
  font-size: 1.5em;
}

@media screen and (max-width: 1280px) {
  .search-container {
    top: 10px;
    right: 50%;
    width: 360px;
    box-shadow: 0px 0px 40px 2px rgba(0, 0, 0, 0.15);
    transform: translate(50%, 0);
  }
}

@media screen and (max-width: 800px) {
  .north-legend {
    top: 50%;
    transform: translate(0, -50%);
    margin-top: 100px;
  }
  .search-container {
    top: 10px;
    right: 50%;
    width: 300px;
    box-shadow: 0px 0px 40px 2px rgba(0, 0, 0, 0.15);
    transform: translate(50%, 0);
  }
}
/* 
@media screen and (max-width: 320px) {
  .search-container {
    top: 10px;
    right: 50%;
    width: 300px;
    box-shadow: 0px 0px 40px 2px rgba(0, 0, 0, 0.15);
    transform: translate(50%, 0);
  }
} */
</style>
