<template>
  <div class="map__container">
    <AsideBig ref="asideBig" class="map__container--menus big" />
    <AsideSmall class="map__container--menus small" @on-show-aside="showAside" />
    <OMap class="map__container--map" />
  </div>
</template>

<script>
import OMap from "./Map/index";
import AsideBig from "./AsideBig";
import AsideSmall from "./AsideSmall";
export default {
  name: "MapLayout",
  components: {
    OMap,
    AsideBig,
    AsideSmall
  },

  methods: {
    showAside() {
      this.$refs["asideBig"].changeVisible(false);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.map__container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &--map {
    flex: 1;
    height: 100%;
  }
}
</style>
