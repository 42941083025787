<template>
  <div class="ol-popup building-popup">
    <el-card shadow="always">
      <div class="arrow"></div>
      <div slot="header" class="header">
        <div class="title">
          <el-tooltip
            :popper-class="'building-popup__tips'"
            effect="dark"
            :content="localizedContent.buildingPopup.info"
            placement="top"
            v-show="isBuildingInfo"
          >
            <i class="el-icon-info"></i>
          </el-tooltip>
          <span class="title-text">
            {{
              type === "building"
                ? localizedContent.buildingPopup.title
                : localizedContent.buildingPopup.openSpaceTitle
            }}
          </span>
        </div>
        <el-button
          class="building-popup__close"
          icon="el-icon-close"
          @click="popupClose"
          type="text"
        ></el-button>
      </div>
      <div>
        <el-table :data="content" stripe :show-header="false" border v-if="isBuildingInfo">
          <el-table-column prop="label"></el-table-column>
          <el-table-column
            prop="value"
            :width="200"
            :show-overflow-tooltip="false"
          ></el-table-column>
        </el-table>
        <el-table :data="content" stripe :show-header="false" border v-else>
          <el-table-column prop="label" :width="180"></el-table-column>
          <el-table-column prop="value" :show-overflow-tooltip="false"></el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["content", "type"],
  name: "OlPopup",
  computed: {
    ...mapGetters({
      localizedContent: "localize/content",
      language: "localize/language"
    }),
    isBuildingInfo: function() {
      if (!this.content[0]) {
        return false;
      } else {
        return (
          this.content[0].label === "Total Roof Area" ||
          this.content[0].label === "建築屋頂總面積" ||
          this.content[0].label === "建筑屋顶总面积"
        );
      }
    }
  },
  methods: {
    popupClose() {
      this.$emit("popupClose");
    }
  },
  watch: {
    language() {
      if (!this.content[0]) {
        return;
      }

      if (
        this.content[0] &&
        (this.content[0].label === "地址" || this.content[0].label === "Address")
      ) {
        this.content[0].label = this.localizedContent.search.address;
        this.content[1].label = this.localizedContent.search.place_name;
        return;
      }

      if (this.type === "building") {
        const value0 = this.content[0].value;
        this.content[0].value = value0.split(" ")[0] + " " + this.localizedContent.units["m2"];

        const value1 = this.content[1].value;
        this.content[1].value = value1.split(" ")[0] + " " + this.localizedContent.units["m2"];

        const value2 = this.content[2].value;
        this.content[2].value = value2.split(" ")[0] + " " + this.localizedContent.units["kWh/m2"];

        const value3 = this.content[3].value;
        this.content[3].value = value3.split(" ")[0] + " " + this.localizedContent.units["kW"];

        const value4 = this.content[4].value;
        this.content[4].value = value4.split(" ")[0] + " " + this.localizedContent.units["kWh"];

        if (this.content.length === 6) {
          const value5 = this.content[5].value;
          this.content[5].value = value5.split(" ")[0] + " " + this.localizedContent.units["hk$"];
        }

        this.content[0].label = this.localizedContent.buildingPopup.totalRoofArea;
        this.content[1].label = this.localizedContent.buildingPopup.areaSuitableForInstallation;
        this.content[2].label = this.localizedContent.buildingPopup.averageSolar;
        this.content[3].label = this.localizedContent.buildingPopup.installationCapacity;
        this.content[4].label = this.localizedContent.buildingPopup.expectedAnnualElectricityGeneration;

        if (this.content.length === 6) {
          this.content[5].label = this.localizedContent.buildingPopup.expectedAnnualFiTIncome;
        }
      } else if (this.type === "openspace") {
        this.content[0].value = this.content[0].options[this.language];

        const value0 = this.content[1].value;
        this.content[1].value = value0.split(" ")[0] + " " + this.localizedContent.units["m2"];

        const value1 = this.content[2].value;
        this.content[2].value = value1.split(" ")[0] + " " + this.localizedContent.units["m2"];

        const value2 = this.content[3].value;
        this.content[3].value = value2.split(" ")[0] + " " + this.localizedContent.units["kWh/m2"];

        const value3 = this.content[4].value;
        this.content[4].value = value3.split(" ")[0] + " " + this.localizedContent.units["kW"];

        const value4 = this.content[5].value;
        this.content[5].value = value4.split(" ")[0] + " " + this.localizedContent.units["kWh"];

        const value5 = this.content[6].value;
        this.content[6].value = value5.split(" ")[0] + " " + this.localizedContent.units["hk$"];

        this.content[0].label = this.localizedContent.buildingPopup.openSpaceType;
        this.content[1].label = this.localizedContent.buildingPopup.openSpaceArea;
        this.content[2].label = this.localizedContent.buildingPopup.areaSuitableForInstallation;
        this.content[3].label = this.localizedContent.buildingPopup.averageSolar;
        this.content[4].label = this.localizedContent.buildingPopup.installationCapacity;
        this.content[5].label = this.localizedContent.buildingPopup.expectedAnnualElectricityGeneration;
        this.content[6].label = this.localizedContent.buildingPopup.expectedAnnualFiTIncome;
      }
    }
  },
  data() {
    return {
      languageCache: [],
      solarConfig: {}
    };
  },
  async mounted() {}
};
</script>

<style lang="less">
.building-popup {
  width: 480px;

  &__tips {
    width: 360px;
    font-size: 1em;
    font-family: Avenir, Helvetica, Arial, sans-serif;
  }

  .el-card__header {
    padding: 0 10px;
  }
  .el-card__body {
    padding: 0;
  }
  .el-table {
    white-space: pre-wrap;
    word-break: normal;
    font-size: 0.7em;

    .cell {
    }
  }

  &__close {
    font-size: 18px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      color: #fdb814;

      &-text {
        margin-left: 10px;
        font-size: 0.8em;
        font-weight: bold;
      }
    }
  }
  .arrow {
    position: absolute;
    left: 50%;
    top: -18px;
    width: 0;
    height: 0;
    transform: translate(-50%, 0);
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #ffffff transparent;
  }
}
@media screen and (max-width: 768px) {
  .building-popup {
    width: 360px;
  }
}
.el-table .cell,
.el-table--border td:first-child .cell,
.el-table--border th:first-child .cell {
  letter-spacing: 1px;
  word-break: keep-all;
}
</style>
