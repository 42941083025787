<template>
  <div class="layer-control">
    <h4 class="layer-control__title">{{ localizedContent.solor_layer_switcher.title }}</h4>

    <div class="layer-control__body">
      <div class="layer-switcher">
        <p>{{ localizedContent.solor_layer_switcher.solar_layer_switch_building }}</p>
        <div class="layer-switcher-funciton">
          <el-switch tabindex="0" v-model="solarRooftopSwitchValue"></el-switch>
        </div>
      </div>
      <div class="layer-switcher">
        <p>
          {{ localizedContent.solor_layer_switcher.solar_layer_switch_open_space }}
        </p>
        <div class="layer-switcher-funciton">
          <el-switch tabindex="2" v-model="solarOpenSpaceSwitchValue"></el-switch>
        </div>
      </div>

      <div class="function-group" tabindex="-1">
        <p tabindex="-1">{{ localizedContent.solor_layer_switcher.panel_efficency }} (%)</p>
        <div tabindex="0">
          <el-input
            tabindex="0"
            class="function__item"
            v-if="inputHidden"
            type="String"
            :clearable="false"
            @focus="handlePanelEfficencyValueFocus"
            :value="panelEfficencyValueChecked"
            :placeholder="localizedContent.solor_layer_switcher.panel_efficency_placeholder"
          ></el-input>
          <el-input
            tabindex="0"
            class="function__item"
            v-else
            :clearable="false"
            type="Number"
            ref="efficency"
            :value="panelEfficencyValue"
            @input="handlePanelEfficencyValueInput"
            :placeholder="localizedContent.solor_layer_switcher.panel_efficency_placeholder"
          ></el-input>
        </div>
      </div>
      <div class="function-group">
        <p>{{ localizedContent.solor_layer_switcher.tilting_angle }}</p>
        <el-select
          tabindex="0"
          class="function__item"
          v-model="tiltingAngleValue"
          :placeholder="localizedContent.solor_layer_switcher.tilting_angle_placeholder"
        >
          <el-option
            v-for="item in tiltingAngleOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <div v-show="tiltingAngleValue !== 0" style="color: red;font-size: small;">
          {{ localizedContent.noOpenSpace }}
        </div>
      </div>
      <div class="function-group">
        <p>{{ localizedContent.solor_layer_switcher.orientation }}</p>
        <el-select
          tabindex="0"
          class="function__item"
          v-model="orientationValue"
          :placeholder="localizedContent.solor_layer_switcher.orientation_placeholder"
        >
          <el-option
            v-for="item in orientationOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="function-group">
        <p class="sliderName">{{ localizedContent.solor_layer_switcher.transparency }}</p>
        <el-slider
          tabindex="0"
          class="function__item"
          v-model="transparencyValue"
          :step="5"
        ></el-slider>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SolarLayerSwitcher",
  computed: {
    ...mapGetters({
      localizedContent: "localize/content",
      language: "localize/language"
    }),
    solarRooftopSwitchValue: {
      get() {
        return this.$store.getters["solarLayer/buildingLayerVisible"];
      },
      set(val) {
        this.$store.commit("solarLayer/set_building_layer_visible", val);
      }
    },
    solarOpenSpaceSwitchValue: {
      get() {
        return this.$store.getters["solarLayer/openSpaceSolarLayerVisible"];
      },
      set(val) {
        this.$store.commit("solarLayer/set_open_space_shp_visible", val);
      }
    },
    panelEfficencyValue: {
      get() {
        return this.$store.getters["solarLayer/panelEfficencyValue"];
      },
      set(val) {
        this.$store.commit("solarLayer/set_panel_efficency_value", val);
      }
    },
    tiltingAngleValue: {
      get() {
        return this.$store.getters["solarLayer/tiltingAngleValue"];
      },
      set(val) {
        this.$store.commit("solarLayer/set_tilting_angle_value", val);
      }
    },
    orientationValue: {
      get() {
        return this.$store.getters["solarLayer/orientationValue"];
      },
      set(val) {
        this.$store.commit("solarLayer/set_orientation_value", val);
      }
    },
    transparencyValue: {
      get() {
        return this.$store.getters["solarLayer/transparencyValue"];
      },
      set(val) {
        this.$store.commit("solarLayer/set_transparency_value", val);
      }
    }
  },
  methods: {
    handlePanelEfficencyValueInput: function(inputValue) {
      // 这个地方来判断值
      if (inputValue) {
        this.panelEfficencyValue = parseFloat(inputValue);
      } else {
        // 值为空时，为默认值
        this.panelEfficencyValue = 0;
      }
      if (inputValue > 100 || inputValue < 0) {
        // Solar Panel Efficiency default value
        this.panelEfficencyValue = 20;
      }
    },
    handlePanelEfficencyValueFocus() {
      this.inputHidden = false;
      this.$nextTick(() => {
        this.$refs["efficency"].focus();
      });
    }
  },
  data() {
    return {
      inputHidden: true,
      panelEfficencyValueChecked:
        this.$store.getters["solarLayer/panelEfficencyValue"] +
        " " +
        this.$store.getters["localize/content"].solor_layer_switcher.default,
      tiltingAngleOptions: Array.apply(null, Array(9)).map((item, i) => {
        return {
          value: i * 5,
          label: i * 5 + "°"
        };
      }),
      transparencyOptions: Array.apply(null, Array(20)).map((item, i) => {
        return {
          value: (i + 1) * 5,
          label: (i + 1) * 5 + "%"
        };
      }),
      orientationOptions: [
        {
          value: 0,
          label: this.$store.getters["localize/content"].solor_layer_switcher.north
        },
        {
          value: 45,
          label: this.$store.getters["localize/content"].solor_layer_switcher.northeast
        },
        {
          value: 90,
          label: this.$store.getters["localize/content"].solor_layer_switcher.east
        },
        {
          value: 135,
          label: this.$store.getters["localize/content"].solor_layer_switcher.southeast
        },
        {
          value: 180,
          label: this.$store.getters["localize/content"].solor_layer_switcher.south
        },
        {
          value: 225,
          label: this.$store.getters["localize/content"].solor_layer_switcher.southwest
        },
        {
          value: 270,
          label: this.$store.getters["localize/content"].solor_layer_switcher.west
        },
        {
          value: 315,
          label: this.$store.getters["localize/content"].solor_layer_switcher.northwest
        }
      ]
    };
  },
  watch: {
    language() {
      //el-select的options不能根据vuex动态响应，所以这里手动根据语言设置一下
      this.orientationOptions = [
        {
          value: 0,
          label: this.$store.getters["localize/content"].solor_layer_switcher.north
        },
        {
          value: 45,
          label: this.$store.getters["localize/content"].solor_layer_switcher.northeast
        },
        {
          value: 90,
          label: this.$store.getters["localize/content"].solor_layer_switcher.east
        },
        {
          value: 135,
          label: this.$store.getters["localize/content"].solor_layer_switcher.southeast
        },
        {
          value: 180,
          label: this.$store.getters["localize/content"].solor_layer_switcher.south
        },
        {
          value: 225,
          label: this.$store.getters["localize/content"].solor_layer_switcher.southwest
        },
        {
          value: 270,
          label: this.$store.getters["localize/content"].solor_layer_switcher.west
        },
        {
          value: 315,
          label: this.$store.getters["localize/content"].solor_layer_switcher.northwest
        }
      ];

      // Solar Panel Efficiency default value
      if (this.$store.getters["solarLayer/panelEfficencyValue"] === 20) {
        this.panelEfficencyValueChecked =
          this.$store.getters["solarLayer/panelEfficencyValue"] +
          this.$store.getters["localize/content"].solor_layer_switcher.default;
      } else {
        this.panelEfficencyValueChecked = this.$store.getters["solarLayer/panelEfficencyValue"];
      }
    }
  }
};
</script>

<style lang="less">
.layer-control {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875em;

  &__title {
    box-sizing: border-box;
    margin: 0;
    padding: 0 15px;
    width: 100%;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;

    height: 40px;
    line-height: 40px;
    background-color: #fffef6;
    color: #000000;

    font-weight: normal;
    font-size: 0.87em;
  }

  &__body {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    padding: 0 20px;
    width: 100%;
    box-sizing: border-box;

    .layer-switcher {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;

      p {
        font-size: 0.85em;
        color: #303030;
      }
    }

    .function-group {
      width: 100%;
      margin: 15px 0;

      p {
        margin: 5px 0;
        width: 100%;
        font-size: 0.85em;
        color: #303030;
        text-align: left;
      }

      .function__item {
        box-sizing: border-box;
        width: 100%;
        width: 100%;
      }
    }
  }
}
</style>
