import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import XYZ from "ol/source/XYZ.js";
import { Stroke, Style, Fill } from "ol/style";
import GeoJSON from "ol/format/GeoJSON";
import { bbox as bboxStrategy } from "ol/loadingstrategy";
import Vue from "vue";
import store from "@/store/index";
import { mapGetters } from "vuex";
import { proj2326Obj } from "./utils";
import TileGrid from "ol/tilegrid/TileGrid";
import WMTS from "ol/source/WMTS";
import WmtsEpsg2326Opts from "./wmtsEpsg2326Opts";
import { LSDMapBaseUrl } from "@/config/";

const tileGrid = new TileGrid({
  extent: proj2326Obj.extent,
  origin: proj2326Obj.hkorigin,
  resolutions: proj2326Obj.resolutions,
  tileSize: 256
});

const topographic_map_layer = new TileLayer({
  title: store.getters["localize/content"].topographic_map.layer_title,
  visible: true,
  opacity: 0.6,
  source: new XYZ({
    url:
      "https://api.hkmapservice.gov.hk/osm/xyz/basemap/HK80/tile/{z}/{x}/{y}.png?key=" +
      store.getters["config/landsdApiKey"],
    crossOrigin: "anonymous",
    tileGrid: tileGrid,
    attributions: [store.getters["localize/content"].topographic_map.attributions],
    attributionsCollapsible: false,
    projection: proj2326Obj.projection
  })
});

const topographic_map_label_layer = new TileLayer({
  title: store.getters["localize/content"].topographic_map_label.layer_title,
  visible: true,
  zIndex: 999,
  source: new XYZ({
    url:
      `https://api.hkmapservice.gov.hk/osm/xyz/label-${store.getters["localize/content"].topographic_map_label.lang}/HK80/tile/{z}/{x}/{y}.png?key=` +
      store.getters["config/landsdApiKey"],
    crossOrigin: "anonymous",
    projection: proj2326Obj.projection,
    tileGrid: tileGrid
  })
});

const satelite_map_layer = new TileLayer({
  title: store.getters["localize/content"].topographic_map.layer_title,
  visible: false,
  source: new XYZ({
    url:
      "https://api.hkmapservice.gov.hk/osm/xyz/imagery/HK80/tile/{z}/{x}/{y}.png?key=" +
      store.getters["config/landsdApiKey"],
    crossOrigin: "anonymous",
    attributions: [store.getters["localize/content"].topographic_map.attributions],
    attributionsCollapsible: false,
    projection: proj2326Obj.projection,
    tileGrid: tileGrid
  })
});

const emsd_satelite_map_layer = new TileLayer({
  title: store.getters["localize/content"].satelite_map.layer_title,
  visible: false,
  source: new WMTS({
    url: `${LSDMapBaseUrl}/geoserver/gwc/service/wmts`,
    // url: store.getters["config/WMTSBaseUrl"],
    layer: "solarMap:hkbasemap",
    matrixSet: WmtsEpsg2326Opts["TILEMATRIXSET"],
    format: WmtsEpsg2326Opts["FORMAT"],
    projection: WmtsEpsg2326Opts["PROJECTION"],
    tileGrid: WmtsEpsg2326Opts["TILE_GRID"],
    style: WmtsEpsg2326Opts["STYLE"],
    wrapX: true
  })
});

const sateliteMaps = [satelite_map_layer, emsd_satelite_map_layer];

let solar_layer = new TileLayer({
  title: store.getters["localize/content"].satelite_map.layer_title,
  visible: true,
  source: new WMTS({
    url: `${LSDMapBaseUrl}/geoserver/gwc/service/wmts`,
    layer: `solarMap:s${store.getters["solarLayer/tiltingAngleValue"]}_a${store.getters["solarLayer/orientationValue"]}`,
    matrixSet: WmtsEpsg2326Opts["TILEMATRIXSET"],
    format: WmtsEpsg2326Opts["FORMAT"],
    projection: WmtsEpsg2326Opts["PROJECTION"],
    tileGrid: WmtsEpsg2326Opts["TILE_GRID"],
    style: WmtsEpsg2326Opts["STYLE"],
    wrapX: true
  })
});

let open_space_solar_layer = new TileLayer({
  title: store.getters["localize/content"].satelite_map.layer_title,
  visible: true,
  source: new WMTS({
    url: store.getters["config/WMTSBaseUrl"],
    layer: `solarMap:openspace_s${store.getters["solarLayer/tiltingAngleValue"]}_a${store.getters["solarLayer/orientationValue"]}`,
    matrixSet: WmtsEpsg2326Opts["TILEMATRIXSET"],
    format: WmtsEpsg2326Opts["FORMAT"],
    projection: WmtsEpsg2326Opts["PROJECTION"],
    tileGrid: WmtsEpsg2326Opts["TILE_GRID"],
    style: WmtsEpsg2326Opts["STYLE"],
    wrapX: true
  })
});

let building_layer = new VectorLayer({
  visible: true,
  minZoom: 16,
  // renderBuffer: 50,
  source: new VectorSource({
    format: new GeoJSON(),
    url: function(extent) {
      return (
        `${store.getters["config/WFSBaseUrl"]}service=WFS&` +
        `version=1.1.0&request=GetFeature&typename=solarMap:building&` +
        `outputFormat=application/json&srsname=EPSG:2326&bbox=${extent.join(",")},EPSG:2326`
      );
    },
    strategy: bboxStrategy
  }),
  style: new Style({
    stroke: new Stroke({
      color: "rgba(75,163,254, 1.0)",
      width: 4
    }),
    fill: new Fill({
      color: "rgba(0, 0, 0, 0)"
    })
  })
});

const open_space_shp_layer = new VectorLayer({
  visible: true,
  minZoom: 15,
  source: new VectorSource({
    format: new GeoJSON(),
    url: function(extent) {
      return (
        `${store.getters["config/WFSBaseUrl"]}service=WFS&` +
        `version=1.1.0&request=GetFeature&typename=solarMap:openspace&` +
        `outputFormat=application/json&srsname=EPSG:2326&bbox=${extent.join(",")},EPSG:2326`
      );
    },
    strategy: bboxStrategy
  }),
  style: new Style({
    stroke: new Stroke({
      color: "rgba(75,163,0, 0.7)",
      width: 2
    }),
    fill: new Fill({
      color: "rgba(75,163, 0, 0.05)"
    })
  })
});

topographic_map_layer.set("id", "Digital Map");
topographic_map_label_layer.set("id", "Digital Map Label");
satelite_map_layer.set("id", "Satelite Image Map");
solar_layer.set("id", "Solar Layer");
open_space_solar_layer.set("id", "Open Space Solar Layer");
building_layer.set("id", "building Layer");

store.commit("drawTool/set_building_layer", building_layer);
store.commit("drawTool/set_open_space_shp_layer", open_space_shp_layer);

const LayerGroup = [
  topographic_map_layer,
  topographic_map_label_layer,
  ...sateliteMaps,
  open_space_solar_layer,
  solar_layer,
  open_space_shp_layer,
  building_layer
];

let layersInstance = new Vue({
  store,
  data() {
    return {
      layers: LayerGroup,
      solar_layer,
      building_layer,
      open_space_shp_layer,
      open_space_solar_layer
    };
  },
  computed: {
    ...mapGetters({
      language: "localize/language",
      sValue: "solarLayer/tiltingAngleValue",
      aValue: "solarLayer/orientationValue",
      solarLayerVisible: "solarLayer/solorLayerVisible",
      buildingLayerVisible: "solarLayer/buildingLayerVisible",
      openSpaceSolarLayerVisible: "solarLayer/openSpaceSolarLayerVisible",
      currentMap: "basemap/currentMap"
    })
  },
  watch: {
    language() {
      for (let index = 0; index < LayerGroup.length; index++) {
        const layer = LayerGroup[index];
        let layerId = layer.getProperties().id;

        switch (layerId) {
          case "Digital Map": {
            layer
              .getSource()
              .setAttributions([store.getters["localize/content"].topographic_map.attributions]);
            layer.set("title", store.getters["localize/content"].topographic_map.layer_title);
            break;
          }
          case "Digital Map Label": {
            layer.setSource(
              new XYZ({
                url:
                  `https://api.hkmapservice.gov.hk/osm/xyz/label-${store.getters["localize/content"].topographic_map_label.lang}/HK80/tile/{z}/{x}/{y}.png?key=` +
                  store.getters["config/landsdApiKey"],
                crossOrigin: "anonymous",
                projection: proj2326Obj.projection,
                tileGrid: tileGrid
              })
            );
            break;
          }
          case "Satelite Image Map": {
            layer
              .getSource()
              .setAttributions([store.getters["localize/content"].satelite_map.attributions]);
            // layer.set("title", store.getters["localize/content"].topographic_map.layer_title);
            break;
          }
          default:
            break;
        }
      }
    },
    sValue() {
      solar_layer.setSource(
        new WMTS({
          url: `${LSDMapBaseUrl}/geoserver/gwc/service/wmts`,
          layer: `solarMap:s${store.getters["solarLayer/tiltingAngleValue"]}_a${store.getters["solarLayer/orientationValue"]}`,
          matrixSet: WmtsEpsg2326Opts["TILEMATRIXSET"],
          format: WmtsEpsg2326Opts["FORMAT"],
          projection: WmtsEpsg2326Opts["PROJECTION"],
          tileGrid: WmtsEpsg2326Opts["TILE_GRID"],
          style: WmtsEpsg2326Opts["STYLE"],
          wrapX: true
        })
      );
      open_space_solar_layer.setSource(
        new WMTS({
          // TODO: Solar Map Service 的 WMTS 地址
          url: store.getters["config/WMTSBaseUrl"],
          layer: `solarMap:openspace_s${store.getters["solarLayer/tiltingAngleValue"]}_a${store.getters["solarLayer/orientationValue"]}`,
          matrixSet: WmtsEpsg2326Opts["TILEMATRIXSET"],
          format: WmtsEpsg2326Opts["FORMAT"],
          projection: WmtsEpsg2326Opts["PROJECTION"],
          tileGrid: WmtsEpsg2326Opts["TILE_GRID"],
          style: WmtsEpsg2326Opts["STYLE"],
          wrapX: true
        })
      );
    },
    aValue() {
      solar_layer.setSource(
        new WMTS({
          url: `${LSDMapBaseUrl}/geoserver/gwc/service/wmts`,
          layer: `solarMap:s${store.getters["solarLayer/tiltingAngleValue"]}_a${store.getters["solarLayer/orientationValue"]}`,
          matrixSet: WmtsEpsg2326Opts["TILEMATRIXSET"],
          format: WmtsEpsg2326Opts["FORMAT"],
          projection: WmtsEpsg2326Opts["PROJECTION"],
          tileGrid: WmtsEpsg2326Opts["TILE_GRID"],
          style: WmtsEpsg2326Opts["STYLE"],
          wrapX: true
        })
      );
      open_space_solar_layer.setSource(
        new WMTS({
          // TODO: Solar Map Service 的 WMTS 地址
          url: store.getters["config/WMTSBaseUrl"],
          layer: `solarMap:openspace_s${store.getters["solarLayer/tiltingAngleValue"]}_a${store.getters["solarLayer/orientationValue"]}`,
          matrixSet: WmtsEpsg2326Opts["TILEMATRIXSET"],
          format: WmtsEpsg2326Opts["FORMAT"],
          projection: WmtsEpsg2326Opts["PROJECTION"],
          tileGrid: WmtsEpsg2326Opts["TILE_GRID"],
          style: WmtsEpsg2326Opts["STYLE"],
          wrapX: true
        })
      );
    },
    solarLayerVisible() {
      solar_layer.setVisible(store.getters["solarLayer/solorLayerVisible"]);
    },
    openSpaceSolarLayerVisible() {
      open_space_shp_layer.setVisible(store.getters["solarLayer/openSpaceSolarLayerVisible"]);
      open_space_solar_layer.setVisible(store.getters["solarLayer/openSpaceSolarLayerVisible"]);
    },
    buildingLayerVisible() {
      solar_layer.setVisible(store.getters["solarLayer/buildingLayerVisible"]);
      building_layer.setVisible(store.getters["solarLayer/buildingLayerVisible"]);
    },
    currentMap: async val => {
      if (val === "shp") {
        topographic_map_layer.setVisible(true);
        satelite_map_layer.setVisible(false);
        sateliteMaps.forEach(layer => layer.setVisible(false));
        // topographic_map_label_layer.bringToFront();
      } else {
        topographic_map_layer.setVisible(false);
        satelite_map_layer.setVisible(true);

        sateliteMaps.forEach(layer => layer.setVisible(true));
        // topographic_map_label_layer.bringToFront();
      }
    }
  }
});

export default layersInstance;
