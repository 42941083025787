<template>
  <div class="selected-res">
    <el-card class="card" shadow="always">
      <div slot="header" class="header">
        <span class="title">{{ localizedContent.solar_result_card.title }}</span>

        <el-button
          @click="closeClick"
          type="text"
          size="mini"
          icon="el-icon-close"
          circle
        ></el-button>
      </div>

      <div class="card-content">
        <div class="item" v-show="typeOptions">
          <div class="label">
            {{ localizedContent.buildingPopup.openSpaceType }}
          </div>
          <div class="content">{{ openSpaceType }}</div>
        </div>
        <div class="item">
          <div class="label">
            {{ localizedContent.solar_result_card.area_selected }}
          </div>
          <div class="content">{{ areaSelectedFormatted }} {{ localizedContent.units["m2"] }}</div>
        </div>
        <div class="item">
          <div class="label">
            {{ localizedContent.solar_result_card.area_suitable_for_PV_installation }}
          </div>
          <div class="content">
            {{ areaSuitableForPVInstallationFormatted }} {{ localizedContent.units["m2"] }}
          </div>
        </div>
        <!--如果畫了一個polygon, 顯示Average Solar Irradiation:   -->
        <div class="item">
          <div class="label">
            {{ localizedContent.buildingPopup.averageSolar }}
          </div>
          <div class="content">
            {{ averageSolarIrradiationFormatted }} {{ localizedContent.units["kWh/m2"] }}
          </div>
        </div>
        <div class="item">
          <div class="label">
            {{ localizedContent.solar_result_card.installation_capacity }}
          </div>
          <div class="content">
            {{ installationCapacityFormatted }} {{ localizedContent.units["kW"] }}
          </div>
        </div>
        <div class="item">
          <div class="label">
            {{ localizedContent.solar_result_card.electricity_generation }}
          </div>
          <div class="content">
            {{ electricityGenerationFormatted }} {{ localizedContent.units["kWh"] }}
          </div>
        </div>
        <div class="item" v-show="fitVisible">
          <div class="label">
            {{ localizedContent.solar_result_card.Fit_income }}
          </div>
          <div class="content">{{ FitIncomeFormatted }} {{ localizedContent.units["hk$"] }}</div>
        </div>
      </div>

      <el-button
        class="calculate"
        size="mini"
        type="primary"
        @click="paybackButtonClick"
        v-if="showCalculate"
      >
        {{ localizedContent.solar_result_card.payback_button }}
      </el-button>
      <el-button
        class="calculate"
        size="mini"
        type="primary"
        @click="paybackButtonClick"
        plain
        v-else
      >
        {{ localizedContent.solar_result_card.payback_button }}
      </el-button>
    </el-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SolarResultCard",
  props: {
    areaSelected: { type: Number },
    areaSuitableForPVInstallation: { type: Number },
    averageSolarIrradiation: { type: Number },
    installationCapacity: { type: Number },
    electricityGeneration: { type: Number },
    FitIncome: { type: Number },
    typeOptions: { type: Object }
  },
  computed: {
    ...mapGetters({
      localizedContent: "localize/content",
      language: "localize/language"
    }),
    areaSelectedFormatted: function() {
      return parseFloat(this.areaSelected).toLocaleString("en-US");
    },
    areaSuitableForPVInstallationFormatted: function() {
      return parseFloat(this.areaSuitableForPVInstallation).toLocaleString("en-US");
    },
    // 拿到Average Solar Irradiation
    averageSolarIrradiationFormatted: function() {
      return parseFloat(this.averageSolarIrradiation).toLocaleString("en-US");
    },
    installationCapacityFormatted: function() {
      return parseFloat(this.installationCapacity).toLocaleString("en-US");
    },
    electricityGenerationFormatted: function() {
      return parseFloat(this.electricityGeneration.toFixed(1)).toLocaleString("en-US");
    },
    FitIncomeFormatted: function() {
      return parseFloat(this.FitIncome.toFixed(1)).toLocaleString("en-US");
    },
    openSpaceType: function() {
      return this.typeOptions ? this.typeOptions[this.language] : "";
    }
  },
  watch: {
    FitIncomeFormatted() {
      if (parseFloat(this.installationCapacity) > 1000) {
        this.fitVisible = false;
      } else {
        this.fitVisible = true;
      }
    }
  },
  methods: {
    closeClick() {
      this.showCalculate = false;
      this.$emit("close");
    },
    paybackButtonClick() {
      if (!this.showCalculate) {
        this.showCalculate = true;
        this.$emit("paybackButtonClick");
      } else {
        this.showCalculate = false;
        this.$emit("closePayback");
      }
    }
  },
  data() {
    return {
      fitVisible: true,
      showCalculate: false
    };
  },
  async mounted() {}
};
</script>

<style lang="less">
.selected-res {
  .el-card {
    border: 0;
    border-top: 0;
    border-radius: 0;
    box-shadow: none;
  }
  .el-card__header {
    padding: 10px;
    font-weight: bold;
    border-bottom: 1px solid #fdb814;
  }
  .el-card__body {
    padding: 0;
    text-align: center;
  }
  .header {
    display: flex;
    align-items: center;

    .title {
      font-size: 0.8em;
      flex-grow: 1;
      color: #fdb814;
      text-align: center;
    }
    .el-button {
      padding: 0;
    }
  }

  .card-content {
    color: #303030;
    font-size: 0.8em;
    .item {
      width: 100%;
      padding: 10px 0;
      display: flex;
      justify-content: space-around;
      border-radius: 4px;
      flex-wrap: nowrap;
      text-align: left;
      box-sizing: border-box;
      font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei",
        "微软雅黑", Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      letter-spacing: 1px;

      &:nth-child(odd) {
        background-color: #fffef6;
      }

      .label {
        flex-grow: 1;
        padding-left: 15px;
      }

      .content {
        margin-right: 15px;
        flex-basis: 45%;
        padding-left: 15px;

        flex-grow: 0;
        flex-shrink: 0;
      }
    }
  }

  .calculate {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 95%;
    padding: 5px;
    font-size: 0.8em;
  }
}

@media screen and (max-width: 540px) {
  .selected-res {
    .el-card__header {
      padding: 10px 15px;
    }
    .el-card__body {
      padding: 0;
      padding-bottom: 0;
    }

    .header {
      .title {
        flex-grow: 1;
        color: #fdb814;
      }
    }

    .card-content {
      font-size: 0.6em;
      color: #303030;

      .item {
        padding: 8px 5px;
        .label {
          padding-left: 10px;
        }
      }
    }
  }
}
</style>
